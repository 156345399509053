<template>
  <el-container>
    <el-header style="height: 80px">
      <!-- <img
        style="width: 80px; height: 80px; margin-left: -20px"
        src="../assets/logo.png"
      /> -->
      <span style="line-height: 80px; text-align: center; font-size: 30px; color: white">北坡课堂管理后台</span>
      <div style="display: flex; flex-direction: row; align-items: center; margin-left: auto">
        <el-avatar :size="50" :src="userInfo.avatar" />
        <span style="text-align: center; line-height: 80px; color: white; margin-left: 10px">{{ userInfo.nickname }}</span>
        <el-button type="danger" style="width: 80px; height: 32px; margin-left: 10px; text-align: center; padding: 0" @click="logout">退出登录</el-button>
      </div>
    </el-header>
    <el-container>
      <el-aside style="width: 200px">
        <el-menu class="el-menu-vertical-demo" router>
          <el-menu-item-group>
            <el-menu-item v-for="(item, index) in menuList" :key="index" :index="item.pagePath" class="title">
              <template slot="title">
                <i :class="item.icon"></i>
                {{ item.menuName }}
              </template>
            </el-menu-item>
          </el-menu-item-group>
        </el-menu>
      </el-aside>
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <!-- 不缓存的页面，不缓存$route.meta.keepAlive为false的组件 -->
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AuthApi from '../request/AuthApi';
export default {
  data() {
    return {
      userInfo: {},
      menuList: [
        {
          menuName: '圈子管理',
          pagePath: '/field',
          icon: 'el-icon-help',
        },
        {
          menuName: '商品管理',
          pagePath: '/spu',
          icon: 'el-icon-goods',
        },
        {
          menuName: '积分商品',
          pagePath: '/spu/points',
          icon: 'el-icon-goods',
        },
        {
          menuName: '积分兑换',
          pagePath: '/exchange/goods',
          icon: 'el-icon-goods',
        },
        {
          menuName: '课程管理',
          pagePath: '/course',
          icon: 'el-icon-date',
        },
        {
          menuName: '文件管理',
          pagePath: '/file',
          icon: 'el-icon-files',
        },
        {
          menuName: '订单管理',
          pagePath: '/order',
          icon: 'el-icon-s-order',
        },
        {
          menuName: '视频号订单',
          pagePath: '/wx/order',
          icon: 'el-icon-s-order',
        },
        {
          menuName: '用户管理',
          pagePath: '/user',
          icon: 'el-icon-user-solid',
        },
        {
          "menuName":"兑换记录",
          "pagePath": "/exchange/record",
          icon: 'el-icon-user-solid',
        },
        {
          menuName: '积分任务',
          pagePath: '/credit/rule',
          icon: 'el-icon-user-solid',
        },
      ],
    };
  },
  mounted() {
    let token = this.$store.getters.getToken;
    if (!token) {
      this.$router.replace({
        path: '/login',
      });
      return;
    }
    AuthApi.getUserInfo().then((res) => {
      if (res.success) {
        this.$store.commit('setUserInfo', res.data);
        this.userInfo = this.$store.getters.getUserInfo;
      }
    });
  },
  methods: {
    async logout() {
      const result = await AuthApi.logout();
      if (result.success) {
        this.$store.commit('clear');
        await this.$router.replace({
          path: '/login',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  height: 100%;
}
.el-header {
  background-color: rgb(64, 158, 255);
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.el-aside {
  position: fixed;
  color: #333;
  text-align: center;
  height: calc(100vh - 80px);
  border-right: 1px solid #e6e6e6;
}
.el-menu {
  height: 100%;
}
.el-menu-item-group__title {
  padding: 0;
}
.el-main {
  margin-left: 200px;
  background-color: #fff;
  color: #333;
  text-align: center;
}
</style>
